/* eslint-disable react/jsx-key */
import React, {PropsWithChildren} from 'react';
import {useSticky} from 'react-table-sticky';
import {
  Row,
  TableInstance,
  TableOptions,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  useTable,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  useSortBy,
  UseSortByColumnOptions,
  UseSortByColumnProps,
  HeaderGroup,
  UseFiltersColumnProps,
  UseFiltersInstanceProps,
  UseSortByInstanceProps,
  SortingRule,
  UseResizeColumnsColumnProps,
  useResizeColumns,
  useBlockLayout,
  useExpanded,
  useColumnOrder,
  UseColumnOrderInstanceProps,
  UseExpandedRowProps,
  useFlexLayout,
} from 'react-table';
import {Box, Tooltip} from '@material-ui/core';
import {useStyles} from '../styles';
import {useTranslation} from 'react-i18next';
import NoResultsRow from '../NoResultsRow';
import classNames from 'classnames';
import Pagination, {PaginationMode} from '../../Pagination';
import SortArrows from '../SortArrows';
import {ITableColumnSettings, TServerSideState} from '../types';
import TableCellLinkWrapper from '../TableCellLinkWrapper';

export const STATIC_CELL_WIDTH = 35;
export const MIN_CELL_WIDTH = 100;
export const MAX_CELL_WIDTH = 200;
export const EXPANDER_CELL_ID = 'expander';
export const SETTINGS_CELL_ID = 'settings';
export const SELECTION_CELL_ID = 'selection';

export type TInstance<T extends {}> = TableInstance<T> &
  UseRowSelectInstanceProps<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> &
  UseColumnOrderInstanceProps<T> &
  UseFiltersInstanceProps<T>;

export type TRow<T extends {}> = Row<T> &
  UseExpandedRowProps<T> &
  UseRowSelectRowProps<T>;

export interface TableProperties<T extends {}>
  extends Omit<TableOptions<T>, 'columns'> {
  columns: ITableColumnSettings[];
  defaultSort?: SortingRule<{}>[];
  noResultsMessage?: string;
  paginationMode?: PaginationMode;
  resultCount: number;
  /**
   * Can be used to specify if row links open
   * in new tabs etc.
   */
  rowLinkTarget?: React.HTMLAttributeAnchorTarget;
  tableState: TServerSideState<T>;

  onChangeState: (state: TServerSideState<T>) => void;
  expandedRowContent?: (row: TRow<T>) => React.ReactNode | null;
  onRowClick?: (id: string) => void;
  getRowLink?: (id: string) => string;
}

const hooks = [
  useSortBy,
  useExpanded,
  useBlockLayout,
  useColumnOrder,
  useResizeColumns,
  useRowSelect,
  useSticky,
  useFlexLayout,
];

export function ServerSideTable<T extends {}>(
  props: PropsWithChildren<TableProperties<T>>,
) {
  const defaultColumnConfig = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 50, // minWidth is only used as a limit for resizing
      width: 100, // width is used for both the flex-basis and flex-grow
      maxWidth: 150, // maxWidth is only used as a limit for resizing
    }),
    [],
  );

  const {
    onRowClick,
    getRowLink,
    rowLinkTarget,
    defaultSort,
    tableState,
    resultCount,
    paginationMode,
    onChangeState,
    expandedRowContent,
    ...reactTable
  } = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const getRowId = React.useCallback((row: TRow<T>) => row.id, []);

  const instance = useTable<T>(
    {
      ...reactTable,
      defaultColumn: defaultColumnConfig,
      getRowId,
      initialState: {
        ...tableState,
        sortBy:
          !tableState.sortBy?.length && defaultSort?.length ? defaultSort : [],
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      autoResetSelectedRows: true,
    } as unknown as TableOptions<T> & UsePaginationOptions<T>,
    ...hooks,
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    state,
    setSortBy,
  } = instance as TInstance<T>;

  // Best way to limit the amount of state update
  const stringifiedState = JSON.stringify(state);
  React.useEffect(() => {
    onChangeState({
      ...state,
      pageIndex: tableState.pageIndex,
      pageSize: tableState.pageSize,
    } as TServerSideState<T>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedState]);

  ///////// State is still updated + triggering re-render every "debounce time"
  // const onChangeStateDebounced = useAsyncDebounce(onChangeState, 100);
  // React.useEffect(() => {
  //   onChangeStateDebounced(state as TServerSideState<T>);
  // }, [onChangeStateDebounced, state]);

  const handleSortBy = (
    column: HeaderGroup<T> &
      UseSortByColumnProps<T> &
      UseResizeColumnsColumnProps<T> &
      Partial<{
        disableSortBy: boolean;
      }>,
  ) => {
    if (!column.isSorted) return setSortBy([{id: column.id, desc: false}]);

    if (column.isSortedDesc === false)
      return setSortBy([{id: column.id, desc: true}]);

    if (defaultSort && defaultSort[0].id === column.id)
      return setSortBy([{id: column.id, desc: !column.isSortedDesc}]);

    setSortBy(defaultSort || []);
  };

  return (
    <Box className={classes.root}>
      <div {...getTableProps()} className={classes.table}>
        {/* Header */}
        <div className={classes.thead}>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className={classes.tr}>
              {headerGroup.headers.map(group => {
                const column = group as HeaderGroup<T> &
                  UseSortByColumnProps<T> &
                  UseFiltersColumnProps<T> &
                  UseResizeColumnsColumnProps<T> &
                  UseSortByColumnOptions<T>;

                const renderedHeader = (): React.ReactNode =>
                  typeof column.render('Header') === 'string' ? (
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        flexGrow: 1,
                      }}
                    >
                      {column.render('Header')}
                    </div>
                  ) : (
                    <div style={{flexGrow: 1}}>{column.render('Header')}</div>
                  );

                const sortingTooltip = () => {
                  if (!column.canSort) return '';

                  if (!column.isSorted)
                    return t('table.tooltips.sortAscending');

                  if (!column.isSortedDesc)
                    return t('table.tooltips.sortDescending');

                  const defaultSortedColumn = defaultSort?.find(rule => {
                    return rule.id === column.id;
                  });
                  if (defaultSortedColumn) {
                    return defaultSortedColumn.desc
                      ? t('table.tooltips.sortAscending')
                      : t('table.tooltips.sortDescending');
                  } else {
                    return t('table.tooltips.resetSort');
                  }
                };

                return (
                  <Tooltip
                    key={column.id}
                    title={sortingTooltip()}
                    placement="bottom"
                    disableHoverListener={!column.canSort}
                    disableFocusListener={!column.canSort}
                    disableTouchListener={!column.canSort}
                  >
                    <div
                      {...column.getHeaderProps()}
                      onClick={() => column.canSort && handleSortBy(column)}
                      className={classNames(classes.th, {
                        [classes.thRowSelect]: column.id === SELECTION_CELL_ID,
                        [classes.thIsSortable]: !column.disableSortBy,
                      })}
                    >
                      {renderedHeader()}
                      <SortArrows
                        disableSortBy={!!column.disableSortBy}
                        isSorted={column.isSorted}
                        isSortedDesc={!!column.isSortedDesc}
                      />
                      {column.canResize && (
                        <div
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          {...column.getResizerProps()}
                          className={classNames(classes.resizer, {
                            [classes.isResizing]: column.isResizing,
                          })}
                        />
                      )}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          ))}
        </div>
        {/* Body */}
        <div {...getTableBodyProps()} className={classes.tbody}>
          {resultCount ? (
            rows.map((row, i) => {
              const currentRow = row as TRow<T>;
              prepareRow(currentRow);
              const nextRow = rows[i + 1] as Row<T> & UseExpandedRowProps<T>;
              const isLastRowInSubGroup =
                currentRow.depth > 0 && nextRow && nextRow.depth === 0;

              const rowLink = !!getRowLink ? getRowLink(row.id) : undefined;

              return (
                <div
                  key={row.id}
                  className={classNames(classes.trBody, {
                    [classes.trBodySubTable]: currentRow.depth !== 0,
                    [classes.trSubBorders]: isLastRowInSubGroup,
                  })}
                  onClick={
                    onRowClick
                      ? e => {
                          const el = e.target as HTMLElement;
                          const isUnclickableCell =
                            el.closest('.row-unclickable');
                          const isFromMenuRowClick =
                            !el.closest(`.${classes.tr}`) ||
                            el.closest('.inline-menu-row');

                          if (isUnclickableCell || isFromMenuRowClick) {
                            return;
                          }

                          onRowClick(row.id);
                        }
                      : undefined
                  }
                >
                  <div
                    {...row.getRowProps()}
                    className={classNames(classes.tr, {
                      [classes.trIsSelected]: currentRow.isSelected,
                      [classes.trIsExpanded]: currentRow.isExpanded,
                      [classes.trIsSubExpanded]:
                        currentRow.isExpanded && currentRow.depth > 0,
                    })}
                  >
                    {row.cells.map(cell => {
                      const renderedCell = (): React.ReactNode =>
                        cell.value ? (
                          <div
                            style={{
                              padding: '3px 3px 3px 2px',
                            }}
                          >
                            {cell.render('Cell')}
                          </div>
                        ) : (
                          cell.render('Cell')
                        );

                      const isSubSelection =
                        cell.column.id === SELECTION_CELL_ID &&
                        (cell.row as UseExpandedRowProps<T> & Row<T>).depth > 0;
                      const isRowUnclickable =
                        cell.column.id === SELECTION_CELL_ID ||
                        cell.column.id === EXPANDER_CELL_ID ||
                        cell.column.id === SETTINGS_CELL_ID;
                      const isCellWrappableWithLink = !isRowUnclickable;
                      return (
                        <div
                          {...cell.getCellProps()}
                          className={classNames(classes.td, {
                            ['row-unclickable']: isRowUnclickable,
                          })}
                        >
                          <TableCellLinkWrapper
                            disabled={!isCellWrappableWithLink}
                            link={rowLink}
                            linkTarget={rowLinkTarget}
                          >
                            {isSubSelection ? '' : renderedCell()}
                          </TableCellLinkWrapper>
                        </div>
                      );
                    })}
                  </div>
                  {expandedRowContent && expandedRowContent(currentRow)}
                </div>
              );
            })
          ) : (
            <NoResultsRow text={props.noResultsMessage ?? ''} />
          )}
        </div>
      </div>
      {/* Pagination */}
      <Pagination
        currentPage={tableState.pageIndex}
        rowAmount={resultCount}
        pageSize={tableState.pageSize}
        pageSizeOptions={[10, 20, 25]}
        mode={paginationMode}
        onChangeCurrentPage={page =>
          onChangeState({...tableState, pageIndex: page})
        }
        onChangePageSize={pageSize =>
          onChangeState({...tableState, pageSize: pageSize, pageIndex: 0})
        }
      />
    </Box>
  );
}
