import * as React from 'react';
import {Tooltip, TooltipProps} from '@material-ui/core';

interface CellMarkProps {
  position: 'up' | 'down';
  color: string;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

export function CellMark({
  children,
  props,
}: {
  children: React.ReactNode;
  props: CellMarkProps;
}) {
  return (
    <Tooltip
      title={props.tooltipText || ''}
      placement={props.tooltipPlacement}
      arrow
    >
      <div>
        <div
          style={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            position: 'absolute',
            top: `${props.position === 'up' ? 0 : 'auto'}`,
            bottom: `${props.position === 'down' ? 0 : 'auto'}`,
            right: 0,
            borderWidth: `${
              props.position === 'up' ? '0 15px 15px 0' : '15px 15px 0 0'
            }`,
            borderColor: `transparent ${props.color} transparent transparent`,
          }}
        ></div>
        {children}
      </div>
    </Tooltip>
  );
}
